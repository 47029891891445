import { render, staticRenderFns } from "./bullsAbove18Months.vue?vue&type=template&id=071ff184&scoped=true&"
import script from "./bullsAbove18Months.vue?vue&type=script&lang=js&"
export * from "./bullsAbove18Months.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071ff184",
  null
  
)

export default component.exports