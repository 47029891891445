<template>
    <div class="row">
        <div class="col-12 mt-3">
            <h3 class="text-primary fs-lg-4 font-poppins-semibold">
                Livestock - Summary (Young Bull)
            </h3>
        </div>
        <div class="col-12 mt-3">
            <s-form @submit="downloadReport">
                <div class="row">
                    <div class="col-lg-2">
                        <validated-vue-select class="c-input-select field-required" multiple
                                              :options="sourceOptions" :rules="{required:true}"
                                              label="Source" v-model="model.source"></validated-vue-select>
                    </div>
                    <div class="col-lg-2">
                        <validated-ajax-vue-select label="Breed" multiple
                                                   v-model="model.breed"
                                                   :url="breedOptionsURL"
                                                   :rules="{required:true}"
                                                   class="c-input-select"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2">
                        <validated-checkbox label="Dead" v-model="model.dead"/>
                    </div>
                    <div class="col-lg-2">
                        <validated-checkbox label="Alive" v-model="model.alive"/>
                    </div>
                    <div class="col-lg-2">
                        <validated-checkbox label="Shed Wise" v-model="model.shed_wise"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-2" v-if="model.shed_wise">
                        <validated-ajax-vue-select multiple
                            class="c-input-select input-border-primary"
                            placeholder="Shed"
                            v-model="model.shed"
                            label="shed"
                            :url="shedOptions"/>
                    </div>
                    <div class="col-lg-2">
                        <validated-select :options="reportTypeOptions"
                                          class="c-input-select field-required"
                                          label="Download Format"
                                          :rules="{required:true}"
                                          v-model="model.format_type"/>
                    </div>
                </div>
                <div class="row mt-lg-9 col-12">
                    <div class="col-12 p-0">
                        <div class="fl-x fl-j-e">
                            <div class="btn-group">
                                <btn class="text-right"
                                     text="Download"
                                     loading-text="Please Wait..."
                                     :loading="loading"
                                />
                                <btn type="reset" @click="BackToHome" text="Cancel" design="basic-b" class="px-4"/>
                            </div>
                        </div>
                    </div>
                </div>
            </s-form>

        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name: 'livestockSummaryYoungBull',
    data () {
        return {
            model: {
                alive: '',
                dead: '',
                source: '',
                breed: '',
                shed_wise: '',
                shed: '',
                format_type: ''
            },
            loading: false,
            breedOptionsURL: urls.reports.optionURLS.breedSelect,
            shedOptions: urls.reports.optionURLS.shedSelect + '?animal=bull',
            sourceOptions: [
                { label: 'All', value: 'All' },
                { label: 'Farm Born', value: 'Farm Born' },
                { label: 'Unit Transfer', value: 'Unit Transfer' },
                { label: 'PT Purchase', value: 'PT Purchase' },
                { label: 'Embryo Transfer', value: 'Embryo Transfer' },
                { label: 'Import Purchase', value: 'Import Purchase' }
            ],
            reportTypeOptions: [
                { label: 'Excel', value: 'Excel' },
                { label: 'Pdf', value: 'Pdf' }
            ]
        };
    },
    methods: {
        BackToHome () {
            this.$router.push({ path: '/reports/' });
        },
        async downloadReport () {
            try {
                this.loading = true;
                const response = await axios.form(urls.reports.bulls.livestockSummaryYoungBull, this.model);
                const json = response.data;
                if (json.error === false) {
                    this.loading = false;
                    window.open(json.url);
                } else {
                    this.loading = false;
                    this.$notify(json.message, 'Oops!', { type: 'warning' });
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }

    }
};
</script>

<style scoped>

</style>
