import { render, staticRenderFns } from "./dueForDehorning.vue?vue&type=template&id=d6e442d0&scoped=true&"
import script from "./dueForDehorning.vue?vue&type=script&lang=js&"
export * from "./dueForDehorning.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6e442d0",
  null
  
)

export default component.exports