import { render, staticRenderFns } from "./dehorningBull.vue?vue&type=template&id=cc2135c4&scoped=true&"
import script from "./dehorningBull.vue?vue&type=script&lang=js&"
export * from "./dehorningBull.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc2135c4",
  null
  
)

export default component.exports