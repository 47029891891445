import { render, staticRenderFns } from "./bullGroupSemenCollection.vue?vue&type=template&id=80fb0a60&scoped=true&"
import script from "./bullGroupSemenCollection.vue?vue&type=script&lang=js&"
export * from "./bullGroupSemenCollection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80fb0a60",
  null
  
)

export default component.exports